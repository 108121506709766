import {
    GET_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILE,
    UPDATE_PROFILE,
    GET_PROFILES,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST
  } from './types';
  
  const initialState = {
    profile: null,
    profiles: [],
    repos: [],
    loading: true,
    error: {}
  };
  
  function profileReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_PROFILE:
      case UPDATE_PROFILE:
        return {
          ...state,
          profile: payload,
          loading: false
        };
      case UPDATE_PROFILE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          profile: payload,
          loading: false
        }
      case UPDATE_PROFILE_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        }
      case GET_PROFILES:
        return {
          ...state,
          profiles: payload,
          loading: false
        };
      case PROFILE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
          profile: null
        };
      case CLEAR_PROFILE:
        return {
          ...state,
          profile: null,
          repos: []
        };
      default:
        return state;
    }
  }
  
  export default profileReducer;
  