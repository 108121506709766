import React from "react";
import { ExclamationTriangle } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory()
  return (
    <div className="container mt-5">
      <h1 className="x-large text-secondary">
        <ExclamationTriangle className="mr-2" />
        404, Page Not Found
      </h1>
      <p className="text-muted">Sorry, this page you requested for does not exist.</p>
      <a href="#!" onClick={history.goBack} className="text-decoration-none">Go back</a>
    </div>
  );
};

export default NotFound;
