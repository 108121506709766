import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../scenes/notfound";
import PrivateRoute from "./PrivateRoute";
import Alert from "../layouts/components/Alert";

// ** Routes & Default Routes
import { guestRoutes, userRoutes } from "./routes";

// const NotAuthorized = lazy(() => import('@src/scenes/NotAuthorized'))

const Routes = (props) => {
  return (
    <>
      <Alert />
      <Switch>
        {guestRoutes.map((route) => (
          <Route {...route} />
        ))}
        {userRoutes.map((route) => (
          <PrivateRoute {...route} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
