import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routing/Routes";

// ** Redux imports
import { Provider } from "react-redux";
import store from "./services/store";
import { loadUser } from "./services/auth/actions";
import { LOGOUT } from "./services/auth/types";
import setAuthToken from "./services/api/setAuthToken";

// ** Global Styles Imports
// *! Start Bootstrap - SB Admin v6.0.1 (https://startbootstrap.com/templates/sb-admin)
import "./styles/App.css";
import "./styles/index.css";
import Spinner from "./layouts/components/Spinner";

const App = () => {
  useEffect(() => {
    document.body.classList.add("sb-nav-fixed");
    document.body.classList.add("d-flex");
    document.body.classList.add("flex-column");
    document.body.classList.add("h-100");
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route component={Routes} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
