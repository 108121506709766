import { combineReducers } from "redux";
import alert from "./alert/reducer";
import auth from "./auth/reducer";
import profile from "./profile/reducer";
import user from "./user/reducer";
import log from "./log/reducer";
import application from "./application/reducer";
import review from "./review/reducer";
import collection from "./collection/reducer";

export default combineReducers({
  alert,
  auth,
  profile,
  user,
  log,
  application,
  review,
  collection,
});
