import {
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    LOAD_USER_FAILURE,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT
  } from './types';
  
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: null
  };
  
  function authReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case LOAD_USER_REQUEST:
        return {
          ...state,
          loading: true
        };
      case LOAD_USER_SUCCESS:
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          user: payload
        };
      case LOAD_USER_FAILURE:
        return {
          ...state,
          isAuthenticated: false,
          loading: false
        };
      case LOGIN_REQUEST:
        return {
          ...state,
          loading: true
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false
        };
      case LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        };
      case LOGOUT:
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null
        };
      case FORGOT_PASSWORD_REQUEST:
        return {
          ...state,
          loading: true
        };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          user: payload
        };
      case FORGOT_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        };
      default:
        return state;
    }
  }
  
  export default authReducer;
  