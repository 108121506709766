import {
  CREATE_REVIEW_FAILURE,
  CREATE_REVIEW_REQUEST,
  CREATE_REVIEW_SUCCESS,
} from "./types";

const initialState = {
  reviews: [],
  review: null,
  loading: false,
  error: {},
};

function reviewReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [payload, ...state.reviews],
        loading: false,
      };
    case CREATE_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default reviewReducer;
