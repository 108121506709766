import { lazy } from "react";

export const guestRoutes = [
  {
    path: "/",
    exact: true,
    component: lazy(() => import("../../scenes/landing")),
  },
  {
    path: "/login",
    exact: true,
    component: lazy(() => import("../../scenes/login")),
  },
  {
    path: "/forgot-password",
    exact: true,
    component: lazy(() => import("../../scenes/forgotPassword")),
  },
  {
    path: "/library",
    exact: true,
    component: lazy(() => import("../../scenes/library"))
  },
  {
    path: '/review',
    exact: true,
    component: lazy(() => import("../../scenes/review"))
  }
];

