export const GET_COLLECTIONS_REQUEST = "GET_COLLECTIONS_REQUEST";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
export const GET_COLLECTIONS_FAILURE = "GET_COLLECTIONS_FAILURE";

export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE";

export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";

export const ADD_COLLECTION_REQUEST = "ADD_COLLECTION_REQUEST";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";
export const ADD_COLLECTION_FAILURE = "ADD_COLLECTION_FAILURE";
