import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOAD_USER_FAILURE,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./types";
import { setAlert } from "../alert/actions";
import api from "../api";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER_REQUEST,
    });
    const res = await api.get("/auth");

    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOAD_USER_FAILURE,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Login User
export const login = (username, password) => async (dispatch) => {
  const body = { username, password };
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });
    const res = await api.post("/auth", body);
    console.log(res);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: LOGIN_FAILURE,
    });
  }
};

// forgot password
export const forgotPassword = (username) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });
    const body = { username };
    const res = await api.post("/auth/forgot-password", body);

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: FORGOT_PASSWORD_FAILURE,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  try {
    const res = await api.post("/auth/logout");
    dispatch({ type: LOGOUT, payload: res.data });
  } catch (err) {
    dispatch(setAlert("Unable to logout", "error"));
  }
};
