import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alert = ({ alerts }) => {
  const notify = (alert) => {
    if (alert.alertType === "success") {
      toast.success(alert.msg);
    } else if (alert.alertType === "error") {
      toast.error(alert.msg);
    }
  };

  return (
    <div>
      {alerts.length > 0 &&
        alerts.map((alert) => (
          <div key={alert.id}>
            {notify(alert)}
            <ToastContainer />
          </div>
        ))}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);