import { lazy } from "react";
import { BoxArrowInUp, Gear, Speedometer2 } from "react-bootstrap-icons";
import { FaBell } from "react-icons/fa";

export const userRoutes = [
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../scenes/dashboard")),
    name: "Dashboard",
    mobile: true,
    icon: <Speedometer2 />,
  },
  {
    path: "/users",
    exact: true,
    component: lazy(() => import("../../scenes/users")),
  },
  {
    path: "/collections",
    exact: true,
    component: lazy(() => import("../../scenes/finance")),
  },
  {
    path: "/registration",
    exact: true,
    component: lazy(() => import("../../scenes/registration")),
    name: "Registration",
    mobile: true,
    icon: <BoxArrowInUp />,
  },
  {
    path: "/notifications",
    exact: true,
    component: lazy(() => import("../../scenes/notifications")),
    mobile: true,
    name: "Notifications",
    icon: <FaBell />,
  },
  {
    path: "/settings",
    exact: true,
    component: lazy(() => import("../../scenes/settings")),
    mobile: true,
    name: "Settings",
    icon: <Gear />,
  },
  {
    path: "/members",
    exact: true,
    component: lazy(() => import("../../scenes/members")),
  },
  {
    path: "/leaders",
    exact: true,
    component: lazy(() => import("../../scenes/leaders")),
  },
  {
    path: "/activity-log",
    exact: true,
    component: lazy(() => import("../../scenes/log")),
  },
  {
    path: "/applications",
    exact: true,
    component: lazy(() => import("../../scenes/applications")),
  },
];
