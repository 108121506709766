import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <small className="text-muted">
        Made with :heart: by{" "}
        <a href="https://jonahgeek.tech" target="_blank">
          Jonathan
        </a>{" "}
        for The Christian Science Society of Kampala. <br />
        Property of The Christian Science Society of Kampala &copy; 2021 Unless
        proven otherwize.
      </small>
    </div>
  );
};

export default Footer;
