import {
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_USER_APPLICATION_FAILURE,
  GET_USER_APPLICATION_REQUEST,
  GET_USER_APPLICATION_SUCCESS,
} from "./types";

const initialState = {
  applications: [],
  application: null,
  loading: true,
  error: {},
};

function applicationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: payload,
      };
    case GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_USER_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        application: payload,
      };
    case GET_USER_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default applicationReducer;
