import {
    GET_ACTIVITIES_FAILURE,
    GET_ACTIVITIES_REQUEST,
    GET_ACTIVITIES_SUCCESS
  } from './types';
  
  const initialState = {
    activities: [],
    loading: true,
    error: {}
  };
  
  function activityReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_ACTIVITIES_REQUEST:
        return {
          ...state,
          loading: true
        };
      case GET_ACTIVITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          activities: payload
        };
      case GET_ACTIVITIES_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload
        };
      default:
        return state;
    }
  }
  
  export default activityReducer;
  