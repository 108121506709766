import {
  ADD_COLLECTION_FAILURE,
  ADD_COLLECTION_REQUEST,
  ADD_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAILURE,
  DELETE_COLLECTION_REQUEST,
  DELETE_COLLECTION_SUCCESS,
  GET_COLLECTIONS_FAILURE,
  GET_COLLECTIONS_REQUEST,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTION_FAILURE,
  GET_COLLECTION_REQUEST,
  GET_COLLECTION_SUCCESS,
} from "./types";

const initialState = {
  collections: [],
  collection: null,
  loading: false,
  error: {},
};

function collectionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COLLECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: payload,
        loading: false,
      };
    case GET_COLLECTIONS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: payload,
        loading: false,
      };
    case GET_COLLECTION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADD_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: [payload, ...state.collections],
        loading: false,
      };
    case ADD_COLLECTION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case DELETE_COLLECTION_REQUEST:
      return {
        ...state,
        error: payload,
        loading: true,
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: state.collections.filter(
          (collection) => collection._id !== payload
        ),
        loading: false,
      };
    case DELETE_COLLECTION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}

export default collectionReducer;
